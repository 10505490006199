<template>
    <statistic-module-component/>
</template>

<script>

import StatisticModuleComponent from "@/components/admin/StatisticModuleComponent";

    export default {
        name: "StatisticModule",
        title: "Estadísticas | Private",
        components: { StatisticModuleComponent }
    }
</script>

<style scoped>

</style>