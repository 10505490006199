<template>
    <v-container fluid class="fill-height text-center mt-0 pt-0">
        <v-card class="fill-height container--fluid" :width="formWidth" flat>
            <v-row justify="center">
                <v-col cols="12" class="align-center justify-center">
                    <v-card :loading="loadingData">
                        <v-form ref="form" v-model="valid" lazy-validation action="/admin/wireframe">
                            <v-card-title class="primary text-h6 grey lighten-2">
                                <span class="headline white--text">{{ formTitle }}</span>
                            </v-card-title>
                            <v-container fluid class="text-center">
                                <v-row class="mt-0" justify="center">
                                    <v-col cols="12">
                                        <v-text-field-money
                                            v-model.number="editedItem.projects_count"
                                            label="Proyectos Inmobiliarios"
                                            v-bind:properties="{
                                                            dense: true,
                                                            readonly: false,
                                                            disabled: false,
                                                            outlined: false,
                                                            clearable: false,
                                                            placeholder: '',
                                                          }"
                                            v-bind:options="{
                                                        locale: 'es-MX',
                                                        length: 10,
                                                        precision: 0,
                                                        empty: null,
                                                      }"
                                        />
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field-money
                                            v-model.number="editedItem.investors_count"
                                            label="Socios Inversores"
                                            v-bind:properties="{
                                                            dense: true,
                                                            readonly: false,
                                                            disabled: false,
                                                            outlined: false,
                                                            clearable: false,
                                                            placeholder: '',
                                                          }"
                                            v-bind:options="{
                                                        locale: 'es-MX',
                                                        length: 10,
                                                        precision: 0,
                                                        empty: null,
                                                      }"
                                        />
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field-money
                                            v-model.number="editedItem.partners_count"
                                            label="Partners Colaboradores"
                                            v-bind:properties="{
                                                            dense: true,
                                                            readonly: false,
                                                            disabled: false,
                                                            outlined: false,
                                                            clearable: false,
                                                            placeholder: '',
                                                          }"
                                            v-bind:options="{
                                                        locale: 'es-MX',
                                                        length: 10,
                                                        precision: 0,
                                                        empty: null,
                                                      }"
                                        />
                                    </v-col>
                                    <v-col cols="12">
                                        <v-text-field-money
                                            v-model.number="editedItem.alliances_count"
                                            label="Alianzas Estratégicas"
                                            v-bind:properties="{
                                                            dense: true,
                                                            readonly: false,
                                                            disabled: false,
                                                            outlined: false,
                                                            clearable: false,
                                                            placeholder: '',
                                                          }"
                                            v-bind:options="{
                                                        locale: 'es-MX',
                                                        length: 10,
                                                        precision: 0,
                                                        empty: null,
                                                      }"
                                        />
                                    </v-col>
                                </v-row>
                            </v-container>
                            <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn :disabled="!valid" color="primary" @click="save">
                                    Guardar
                                </v-btn>
                            </v-card-actions>
                        </v-form>
                    </v-card>
                </v-col>
            </v-row>
        </v-card>
        <v-snackbar
            v-model="snackBar"
            :timeout="2000">
            {{ snackText }}
            <template v-slot:action="{ attrs }">
                <v-btn v-bind="attrs"
                       icon
                       @click="snackBar = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
            </template>
        </v-snackbar>
    </v-container>
</template>

<script>
    import statisticService from "@/providers/StatisticService";
    
    export default {
        name: "StatisticModuleComponent",
        data: () => ({
            snackBar: false,
            snackText: '',
            focus: false,
            valid: true,
            loadingData: false,
            textRules: [
                v => !!v || 'Los datos de este campo son obligatorio!'
            ],
            editedIndex: -1,
            editedItem: {
                id: 0,
                projects_count: 0,
                investors_count: 0,
                partners_count: 0,
                alliances_count: 0
            },
            defaultItem: {
                id: 0,
                projects_count: 0,
                investors_count: 0,
                partners_count: 0,
                alliances_count: 0
            }
        }),
        computed: {
            formTitle() {
                return "Estadísticas";
            },
            formWidth() {
                return this.$vuetify.breakpoint.smAndDown ? "100vw" : "50vw";
            }
        },
        methods: {
            getStatistics() {
                this.loadingData = true;
                statisticService.getViewData().then(record => {
                    // console.log("record: ", record.value);
                    if (record.value !== null) {
                        this.editedItem = record.value;
                        this.editedIndex = this.editedItem.id;
                    } else {
                        this.editedItem = this.defaultItem;
                        this.newItem();
                    }
                    this.loadingData = false;
                });
            },
            newItem() {
                this.editedIndex = -1;
                this.valid = false;
            },
            save() {
                this.$refs.form.validate();
                if (this.$refs.form.validate(true)) {
                    if (this.editedIndex > -1) {
                        // Actualizar el registro por el metodo PUT
                        statisticService.updateRecord(this.editedItem).then(result => {
                            if (result.success) {
                                statisticService.getViewData();
                                this.getStatistics();
                            }
                        });
                    } else {
                        // Agrega el registro por el metodo POST
                        statisticService.addRecord(this.editedItem).then(result => {
                            if (result.success) {
                                statisticService.getViewData();
                                this.getStatistics();
                            }
                        });
                    }
                    this.snackText = 'Datos del formulario guardado con éxito...';
                    this.snackBar = true;
                }
            }
        },
        mounted() {
            this.editedItem = this.defaultItem;
            this.getStatistics();
        }
    }
</script>

<style scoped>
    .v-btn {
        text-transform: none !important;
    }
</style>