import axios from "axios";
import store from "@/store/store.js";

export default new class {

    #baseUrl = null;
    errormsg = '';
    message = null;
    header = {
        headers: {
            'Content-Type': 'application/json',
            'X-Requested-With': 'XMLHttpRequest',
            Authorization: 'Bearer ' + store.getters.getToken
        }
    };

    constructor() {
        // Inicializar campos y propiedades.
        this.#baseUrl = store.getters.getBaseURL;
    }

    async getAllRecords() {
        try {
            let response = await axios.get(this.#baseUrl + "/statistic");
            // console.log('response: ', response);
            if (response.data.success) {
                // console.log("response.data:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getViewData() {
        try {
            let response = await axios.get(this.#baseUrl + "/view-data/statistic");
            // console.log('response: ', response);
            if (response.data.success) {
                // console.log("record:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async getRecord(id) {
        try {
            let response = await axios.get(`${this.#baseUrl}/statistic/${id}`);
            if (response.data.success) {
                // console.log("records:", response.data);
                return response.data;
            } else {
                return null;
            }
        } catch (error) {
            return this.handleError(error);
        }
    }

    async addRecord(formData, imageFile) {
        // console.log('Adding Record.', formData);
        let result = null;
        let recordData = new FormData();
        recordData.append("id", formData.id);
        recordData.append("projects_count", formData.projects_count);
        recordData.append("investors_count", formData.investors_count);
        recordData.append("partners_count", formData.partners_count);
        recordData.append("alliances_count", formData.alliances_count);

        // console.log('Record-Data: ', recordData);
        try {
            let response = await axios.post(this.#baseUrl + "/statistic", recordData);
            if (response.data.success) {
                let data = response.data;
                let record_id = data.id;
                this.uploadImage(record_id, imageFile);
                result = response.data;
            } else {
                result = null;
            }
        } catch (error) {
            result = this.handleError(error);
        }
        return result;
    }

    // Pushes posts to the server when called.
    async updateRecord(formData, imageFile) {
        let result = null;
        // console.log('Updating Record: ', formData);
        let recordData = new FormData();
        recordData.append("id", formData.id);
        recordData.append("projects_count", formData.projects_count);
        recordData.append("investors_count", formData.investors_count);
        recordData.append("partners_count", formData.partners_count);
        recordData.append("alliances_count", formData.alliances_count);
        recordData.append('_method', 'put');
        // console.log('Record-Data: ', recordData);
        try {
            let response = await axios.post(`${this.#baseUrl}/statistic/${formData.id}`, recordData);
            if (response.data.success) {
                let data = response.data;
                let record_id = data.id;
                this.uploadImage(record_id, imageFile);
                result = response.data;
            } else {
                result = null;
            }
        } catch (error) {
            result = this.handleError(error);
        }
        return result;
    }

    deleteRecord(id) {
        let result = null;
        // console.log('Deleting Record: %s ', id);
        axios.delete(`${this.#baseUrl}/statistic/${id}`)
            .then(response => {
                // console.log('Respuesta: ', response.data);
                result = response.data;
            })
            /* handle error */
            .catch(error => {
                    // console.log('Error: ', response);
                    result = this.handleError(error);
                }
            );
        return result;
    }

    handleError(error) {
        let valueError;
        if (error.response) {
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            // console.log('Error response: %s', error.response.data.message);
            valueError = error.response.data.messaje;
        } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            valueError = error.request.data;
            // console.log(error.request);
            // console.error('Error request: ', error.request.data);
        } else {
            // Something happened in setting up the request and triggered an Error
            valueError = error.message;
            // console.log('Error', error.message);
        }
        // console.log(error.config);
        return valueError;
    }
}
