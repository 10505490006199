var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-container',{staticClass:"fill-height text-center mt-0 pt-0",attrs:{"fluid":""}},[_c('v-card',{staticClass:"fill-height container--fluid",attrs:{"width":_vm.formWidth,"flat":""}},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{staticClass:"align-center justify-center",attrs:{"cols":"12"}},[_c('v-card',{attrs:{"loading":_vm.loadingData}},[_c('v-form',{ref:"form",attrs:{"lazy-validation":"","action":"/admin/wireframe"},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',{staticClass:"primary text-h6 grey lighten-2"},[_c('span',{staticClass:"headline white--text"},[_vm._v(_vm._s(_vm.formTitle))])]),_c('v-container',{staticClass:"text-center",attrs:{"fluid":""}},[_c('v-row',{staticClass:"mt-0",attrs:{"justify":"center"}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field-money',{attrs:{"label":"Proyectos Inmobiliarios","properties":{
                                                        dense: true,
                                                        readonly: false,
                                                        disabled: false,
                                                        outlined: false,
                                                        clearable: false,
                                                        placeholder: '',
                                                      },"options":{
                                                    locale: 'es-MX',
                                                    length: 10,
                                                    precision: 0,
                                                    empty: null,
                                                  }},model:{value:(_vm.editedItem.projects_count),callback:function ($$v) {_vm.$set(_vm.editedItem, "projects_count", _vm._n($$v))},expression:"editedItem.projects_count"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field-money',{attrs:{"label":"Socios Inversores","properties":{
                                                        dense: true,
                                                        readonly: false,
                                                        disabled: false,
                                                        outlined: false,
                                                        clearable: false,
                                                        placeholder: '',
                                                      },"options":{
                                                    locale: 'es-MX',
                                                    length: 10,
                                                    precision: 0,
                                                    empty: null,
                                                  }},model:{value:(_vm.editedItem.investors_count),callback:function ($$v) {_vm.$set(_vm.editedItem, "investors_count", _vm._n($$v))},expression:"editedItem.investors_count"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field-money',{attrs:{"label":"Partners Colaboradores","properties":{
                                                        dense: true,
                                                        readonly: false,
                                                        disabled: false,
                                                        outlined: false,
                                                        clearable: false,
                                                        placeholder: '',
                                                      },"options":{
                                                    locale: 'es-MX',
                                                    length: 10,
                                                    precision: 0,
                                                    empty: null,
                                                  }},model:{value:(_vm.editedItem.partners_count),callback:function ($$v) {_vm.$set(_vm.editedItem, "partners_count", _vm._n($$v))},expression:"editedItem.partners_count"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field-money',{attrs:{"label":"Alianzas Estratégicas","properties":{
                                                        dense: true,
                                                        readonly: false,
                                                        disabled: false,
                                                        outlined: false,
                                                        clearable: false,
                                                        placeholder: '',
                                                      },"options":{
                                                    locale: 'es-MX',
                                                    length: 10,
                                                    precision: 0,
                                                    empty: null,
                                                  }},model:{value:(_vm.editedItem.alliances_count),callback:function ($$v) {_vm.$set(_vm.editedItem, "alliances_count", _vm._n($$v))},expression:"editedItem.alliances_count"}})],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"disabled":!_vm.valid,"color":"primary"},on:{"click":_vm.save}},[_vm._v(" Guardar ")])],1)],1)],1)],1)],1)],1),_c('v-snackbar',{attrs:{"timeout":2000},scopedSlots:_vm._u([{key:"action",fn:function({ attrs }){return [_c('v-btn',_vm._b({attrs:{"icon":""},on:{"click":function($event){_vm.snackBar = false}}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.snackBar),callback:function ($$v) {_vm.snackBar=$$v},expression:"snackBar"}},[_vm._v(" "+_vm._s(_vm.snackText)+" ")])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }